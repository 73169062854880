/************************************
        PAGES: HOME (INDEX)
************************************/
$slider-header-width: 570px;
$slider-header-height: 57px;
$slider-header-height-xs: $slider-header-height - 10;

.home-slider-wrapper {
    position: relative;
}

#home-slider {
    .item {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 60%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;

        a {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
    }

    @include responsive($view-xs) {
        .item {
            padding-bottom: 75%;
        }
    }
}

.home-slider-controllers {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;

    .home-slider-header {
        position: relative;
        height: $slider-header-height;
        max-width: $slider-header-width;
        margin: 0 auto;
        background-color: rgba(black, 0.5);
        color: white;
        text-align: center;
        padding: 0 $slider-header-height;

        .slider-control {
            position: absolute;
            top: 0;
            width: $slider-header-height;
            height: 100%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            cursor: pointer;
            opacity: 0.7;
            transition: all 0.4s;

            &:hover {
                opacity: 1;
            }

            &[data-direction="left"] {
                left: 0;
                background-image: url('../img/icons/icon-left.png');
            }

            &[data-direction="right"] {
                right: 0;
                background-image: url('../img/icons/icon-right.png');
            }
        }

        h2 {
            font-weight: normal;
            line-height: $slider-header-height;

            a {
                display: none;

                &.active {
                    display: inline-block;
                }
            }
        }
    }

    @include responsive($view-xs) {
        .home-slider-header {
            height: $slider-header-height-xs;
            padding: 0 $slider-header-height-xs;

            .slider-control {
                width: $slider-header-height-xs;
                background-size: auto 60%;
            }

            h2 {
                font-size: rem(18);
                line-height: $slider-header-height-xs;
            }
        }
    }
}
