$map-height: 440px;

.pg-title {
    background-color: #f9f9f9;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding: 1rem;
    color: $color-primary;

    @include responsive($view-xs) {
        padding: 0.7rem;
        font-size: 1.2rem;
    }
}

.il-block {
    padding: 2rem 1rem;
    text-align: center;

    &.block-bordered {
        border-top: solid 2px $color-light;
        border-bottom: solid 2px $color-light;
    }
}

.il-social {
    text-align: center;
    padding: 2rem 1rem;
}

#il-map {
    height: $map-height;

    @include responsive($view-xs) {
        height: $map-height - 140px;
    }
}
