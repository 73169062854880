/************************************
     CONFIGURATION & RESET
************************************/

/****** GOOGLE FONTS IMPORT ******/
@import url(https://fonts.googleapis.com/css?family=Raleway:600);

@font-face {
    font-family: 'Brandon-Text';
    src: url('../fonts/brandon_text_regular.ttf') format('truetype'),
    	 url('../fonts/brandon_text_regular.woff') format('woff'),
    	 url('../fonts/brandon_text_regular.svg#brandon_text_regularregular') format('svg');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'Brandon-Text';
    src: url('../fonts/brandon_textmedium.ttf') format('truetype'),
    	 url('../fonts/brandon_textmedium.woff') format('woff'),
    	 url('../fonts/brandon_textmedium.svg#brandon_textmedium') format('svg');
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: 'Brandon-Text';
    src: url('../fonts/brandon_textbold.ttf') format('truetype'),
    	 url('../fonts/brandon_textbold.woff') format('woff'),
    	 url('../fonts/brandon_textbold.svg#brandon_textbold') format('svg');
    font-weight: 700;
    font-style: normal
}

*, *:before, *:after {
  box-sizing: border-box;
  /*** Optional ***/
  //margin: 0;
  //padding: 0;
}

html, body {
  margin: 0;
  padding: 0;
  /***  Optional ***/
  height: 100%;
}

html {
  font-size: 1rem;
}

body {
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  //color: $color-text;
}

a {
  color: inherit;
  /*** Or ***/
  //color: $color-link;
  text-decoration: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol, ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#wrapper {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin-bottom: $footer-height * -1; //değeri footer yüksekliği kadar veriyoruz
}

.footer-holder {
    height: $footer-height;
}
