/************************************
          PARTIALS: FOOTER
************************************/
footer {
    text-align: right;
    color: $color-primary;
    padding: 20px 0;

    p {
        margin: 0 0 5px;
    }

    .optimus-icon {
        display: inline-block;
        padding-left: 17px;
        background: url('../img/icons/optimus-icon.png') no-repeat left center;
        margin-bottom: 0;
    }
}
