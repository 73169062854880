/************************************
               MIXINS
************************************/

/* Clearfix for parent of floated elements */
@mixin clearfix {
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}

/* Px to Rem Unit */
@function rem($pixel) {
  @return ($pixel / 16) + rem;
}

/* Responsive properties depend on max-width
  since we use desktop first design */
@mixin responsive($viewport) {
	@media screen and (max-width: $viewport) {
		@content;
	}
}

/* block-link */
@mixin block-link($perc, $title) {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: $perc;
    margin-bottom: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    &:hover #{$title} {
        bottom: 0;
    }

    #{$title} {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -100%;
        margin: 0;
        padding: 15px;
        background-color: rgba(black, 0.5);
        color: white;
        text-align: center;
        font-weight: 300;
        transition: all 0.3s;
    }
}
