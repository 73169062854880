.sn-film {
    @include block-link(140%, ".sn-film-title");
}

.vd-clip {
    @include block-link(56%, ".vd-clip-title");
}

.tv-show {
    @include block-link(56%, ".tv-show-title");
}


/* DETAY SAYFALARI SIDEBAR */
.detail-content {
    border-right: solid 1px $color-primary;
    padding-right: 15px;
}

.detail-sidebar {
    @include clearfix;
    color: $color-primary;

    .detail-block {
        float: left;
        width: 100%;
        margin-bottom: rem(20);

        .detail-block-title {
            color: $color-dark;
            margin: 0;
            font-size: rem(20);
            font-weight: 600;
        }

        .detail-block-desc {
            margin: 0;
            font-size: rem(16);
        }
    }

    @include responsive($view-sm) {
        .detail-block {
            width: 50%;

            .detail-block-title {
                font-size: rem(16);
            }

            .detail-block-desc {
                font-size: rem(14);
            }
        }
    }

    @include responsive($view-xs) {
        padding-left: 0;
        border-left: none;

        .detail-block {
            width: 100%;
            text-align: center;
        }
    }
}

/* MÜZİK VİDEO DETAY SAYFASI */
.video-cover-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    &.video-show {
        .video-cover-overlay {
            display: none;
        }
    }

    iframe, video {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: none;
    }

    .video-cover-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-size: cover;

        .video-cover-play-button {
            position: absolute;
            width: rem(64);
            height: rem(64);
            left: 50%;
            top: 50%;
            background: url('../img/icons/play-button.png') no-repeat;
            background-size: contain;
            cursor: pointer;
            opacity: 0.7;
            transform: translate(-50%, -50%);
            transition: all 0.4s;

            &:hover {
                opacity: 1;
            }
        }
    }
}


.mv-detail-item {
    margin-bottom: rem(30);

    .mv-body {
        .mv-title {
            margin: 0 0 rem(15);
            color: $color-dark;
        }

        .mv-description {
            margin: rem(30) 0;
            color: $color-primary;
        }
    }

    @include responsive($view-sm) {
        .mv-body {
            .mv-title {
                font-size: rem(18);
            }

            .mv-description {
                font-size: rem(14);
                margin-top: 0;
            }
        }
    }
}


/* SİNEMA DETAY SAYFASI */
.movie-header {
    color: $color-dark;

    .movie-title {
        font-size: rem(28);
        margin: rem(10) 0 0;
    }

    .movie-details {
        span {
            display: inline-block;
            margin-right: rem(5);
            padding-right: rem(7);
            border-right: solid 1px $color-dark;

            &:last-child {
                border-right: none;
            }
        }
    }

    .movie-desc {
        color: $color-primary;
        margin: rem(30) 0;
    }
}

.movie-cover {
    img {
        width: 100%;
        max-width: 100%;
    }
}

/* CAST SLIDER  */
.slider-item-data {
    display: none;
}

#movie-cast-slider {

    .item {
        @include clearfix;

        .img-wrapper {
            float: left;
            width: 50%;
            padding-bottom: 15px;

            .img-self {
                width: 100%;
                height: 0;
                padding-bottom: 107%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 50% 50%;
            }

            &:nth-child(odd) {
                padding-right: 7.5px;
            }

            &:nth-child(even) {
                padding-left: 7.5px;
            }
        }
    }

    .owl-pagination {
        .owl-page {
            span {
                background: #bf9b8f;
            }

            &.active span {
                background: $color-dark;
            }
        }
    }

}

/* TELEVİZYON DETAY */
.video-tv {
    padding-bottom: 45%;
}

.tv-detay-header {
    color: $color-dark;
    margin-bottom: rem(20);

    .tv-detay-title {
        margin: rem(20) 0;
        font-size: rem(28);
        font-weight: 600;
    }

    .tv-detay-desc {
        color: $color-primary;
    }
}

#tv-detay-gallery {
    width: auto;
    height: auto;
    margin-top: rem(30);
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    > a {
        display: inline-block;
        vertical-align: middle;
        margin-right: rem(15);
    }

    @include responsive($view-sm) {
        > a {

            img {
                height: 100px;
            }
        }
    }
}
