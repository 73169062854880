/************************************
          PARTIALS: HEADER
************************************/
header {
    padding: 15px 0 25px;

    .main-logo {
        float: left;
    }

    .tools-right {
        float: right;
    }

    .hamburger-wrapper {
        text-align: right;
        display: none;

        .mb-hamburger {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 30px;
            cursor: pointer;

            span {
                position: absolute;
                width: 100%;
                height: 20%;
                left: 0;
                background-color: $color-dark;
                transition: all 0.4s;

                &:nth-child(1) {
                    top: 0;
                }

                &:nth-child(2) {
                    top: 40%;
                }

                &:nth-child(3) {
                    top: 80%;
                }
            }

            &.active {
                span {
                    &:nth-child(1), &:nth-child(3) {
                        top: 40%;
                        width: 80%;
                        left: 10%;
                    }

                    &:nth-child(1) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(2) {
                        opacity: 0;
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    .main-menu {
        text-align: right;

        li {
            display: inline-block;
            margin-left: 35px;

            a {
                color: $color-primary;
                font-size: 20px;
            }

            &.active a {
                font-weight: bold;
            }
        }
    }

    @include responsive($view-md) {
        .main-logo {
            position: absolute;
            left: 15px;
            top: 0;

            img {
                width: 80px;
            }
        }

        .tools-right {
            width: 100%;
        }

        .hamburger-wrapper {
            display: block;
        }

        .main-menu {
            text-align: center;
            display: none;

            ul {
                padding: 20px 0 10px;
            }

            li {
                display: block;
                margin: 0;
                margin-bottom: 10px;
            }
        }
    }
}

.social-links {
    text-align: right;
    padding: 45px 0 10px;

    li {
        display: inline-block;

        a {
            display: block;
            width: 30px;
            height: 22px;
            background-position: 50% 50%;
            background-repeat: no-repeat;

            &.social-facebook {
                background-image: url('../img/icons/facebook.png');
            }

            &.social-twitter {
                background-image: url('../img/icons/twitter.png');
            }

            &.social-gplus {
                background-image: url('../img/icons/g-plus.png');
            }

            &.social-instagram {
                background-image: url('../img/icons/instagram.png');
            }

            &.social-youtube {
                background-image: url('../img/icons/youtube.png');
            }
        }
    }

    @include responsive($view-md) {
        padding: 0 0 15px;
    }
}
